export function useKeyboard() {
  // 判断手机 - ios/andriod
  function isIOS() {
    const u = navigator.userAgent;
    return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  }
  function focus() {
    if (isIOS()) {
      window.addEventListener('focusin', function () {
        if (
          document.activeElement.tagName === 'INPUT' ||
          document.activeElement.tagName === 'TEXTAREA'
        ) {
          setTimeout(function () {
            if (document.activeElement.id == 'input') {
              document.documentElement.scrollTop = document.body.scrollHeight;
            // document.activeElement.scrollIntoView();

            }else{
            document.activeElement.scrollIntoView();
            }
          }, 200);
        }
      });
    } else {
      window.addEventListener('resize', function () {
        if (
          document.activeElement.tagName === 'INPUT' ||
          document.activeElement.tagName === 'TEXTAREA'
        ) {
          setTimeout(function () {
            document.activeElement.scrollIntoView();
          }, 100);
        }
      });
    }
  }
  return {
    focus,
  };
}
