<template>
  <div class="bottom-bar">
    <div v-show="props.showBottom" class="input-box" @click.stop>
      <div class="flexBox">
        <img @click="handleVoiceClick" src="@assets/recorder.png" alt="" />
        <div class="rt">
          <textarea
            type="text"
            v-model="text"
            id="input"
            @click="clickInput"
            :rows="1"
            @input="autoResize"
            placeholder="请在此输出您要提问的内容"
          />
          <button
            class="btn"
            @click.stop="send"
            :disabled="messageStore.status != 'init'"
          >
            发送
          </button>
        </div>
      </div>
      <div v-show="showRecorder" class="recorder-box" @click.stop>
        <div class="prompt-loader">
          <div v-for="item in 15" :key="item" class="em emLeft"></div>
          <img src="@assets/start.png" alt="" />
          <div v-for="item in 15" :key="item" class="em emRight"></div>
        </div>
        <div class="btnBox">
          <img src="@assets/tuichu.png" alt="" @click="handleVoiceEnd" />
          <img src="@assets/reback.png" alt="" @click="clickInput" />
        </div>
      </div>
      <!-- <div class="infos">回复内容由开水壶AI深度合成，非人工编辑</div> -->
    </div>
    <div class="goIndex" v-show="!props.showBottom">
      <span class="click" @click="goUpIndex">点这里,</span>
      <span>清空会话历史接着聊</span>
    </div>
    <div class="extra" v-if="props.showExtra">
      <img src="@assets/extra.png" alt="" @click="showPopup = true" />
      <div class="list-container">
        <div class="list">
          <div
            class="item"
            v-for="(item, index) in recommendList"
            :key="index"
            @click="selectRecommend(item)"
          >
            {{ item.recommendTitle }}
          </div>
        </div>
      </div>
    </div>
    <van-overlay
      :show="showPopup"
      @click="showPopup = false"
      :custom-style="{ background: 'rgba(0,0,0,0.3)' }"
    >
    </van-overlay>
    <transition name="van-slide-up">
      <div class="popup-bottom" v-show="showPopup">
        <div class="line" @click="showPopup = false"></div>
        <div class="title">尝试这样问我:</div>
        <div class="list">
          <van-list
            @load="getRecommend('load')"
            offset="50"
            v-model:loading="loading"
            :finished="finished"
          >
            <div
              class="item"
              v-for="(item, index) in recommendList"
              :key="index"
              @click="selectRecommend(item)"
            >
              {{ item.recommendTitle }}
            </div>
          </van-list>
        </div>
      </div>
    </transition>
  </div>
</template>
<script setup>
import { watch, ref, defineEmits, onMounted, defineProps } from 'vue';
import { useMessageStore } from '@/stores/message';
import { getUserRecommend } from './api.js';
import { showToast } from 'vant';
import { useKeyboard } from '@/hooks/common.js';
import { androidInput, isIOS } from '@/utils/utils.js';
import { luyin } from '@/recorder/IatRecorder.js';

let {
  IatRecorder,
  luyinStart,
  luyinStop,
  iatWS,
  resultText,
  showRecorder,
} = luyin();
const iatRecorder = new IatRecorder('en_us', 'mandarin', '6d1b5e69'); //小语种-中文方言-appId
const { focus } = useKeyboard();
const emit = defineEmits(['send']);
let text = ref('');
const recommendList = ref([]);
const messageStore = useMessageStore();
const showPopup = ref(false);
const props = defineProps({
  showExtra: {
    type: Boolean,
    default: true,
  },
  showBottom: {
    type: Boolean,
    default: true,
  },
});
watch(resultText, (n, o) => {
  text.value = n;
  autoResize()
});

onMounted(() => {
  getRecommend();
  focus();
  androidInput();
  window.postMicrophone = function () {
    luyinStart()
  }
});
const recommendPage = ref({
  pageSize: 10,
  pageNum: 1,
});
const finished = ref(false);
const loading = ref(false);
function getRecommend() {
  getUserRecommend(recommendPage.value).then((res) => {
    loading.value = false;
    recommendPage.value.pageNum++;
    if (res.code === 1) {
      if (!res.data.length) {
        finished.value = true;
      }
      recommendList.value.push(...res.data);
    }
  });
}
// 清空会话历史接着聊
function goUpIndex() {
  window.history.go(-1);
}
function handleVoiceClick(){
  let u = navigator.userAgent;
  let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
  let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  if (isiOS) {
    window.webkit.messageHandlers.AppModel.postMessage("getMicrophone");
  }
  if(isAndroid){
    // luyinStart()
    android.getMicrophone();
  }
  autoResize()
}
// 结束识别
function handleVoiceEnd() {
  luyinStop();
  text.value = resultText.value;
}

function send() {
  if(localStorage.getItem('reloadPage')){
    localStorage.removeItem("reloadPage");
  }
  luyinStop();
  if (!text.value) {
    showToast('请输入内容');
    return;
  }
  setTimeout(() => {
    emit('send', text.value);
    text.value = '';
    autoResize()
  }, 300);
}
function selectRecommend(item) {
  text.value = item.recommendTitle;
  showPopup.value = false;
}
 function autoResize() {
      let myTextArea = document.getElementById("input"); // 获取textarea元素
      let rowNumber = Math.ceil(text.value.length/15)
      if(rowNumber > 10) rowNumber = 10
      if(!rowNumber) rowNumber = 1
      myTextArea.rows = rowNumber == 1 ? 1 : rowNumber+1 // 计算行数并更新高度
      // console.log(rowNumber,'rowNumberrowNumber')
  }
function clickInput() {
  luyinStop();
  let input = document.getElementById('input');
  if (isIOS()) {
    if (input) {
      input.focus();
    }
  } else {
    setTimeout(() => {
      if (input) {
        input.focus();
      }
    }, 50);
  }
}
</script>

<style lang="less" scoped>
button {
  outline: none;
  border: none;
}

.bottom-bar {
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px;
  background: #fff;
  .goIndex {
    font-size: 14px;
    text-align: center;
    color: #1d1d2c;
    .click {
      color: #5381ff;
    }
  }
  .recorder-box {
    .btnBox {
      margin: 30px 0 12px 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  .input-box {
    .flexBox {
      display: flex;
      align-items: end;
      gap: 8px;
    }
    img {
      width: 24px;
      height: 24px;
      margin-bottom: 12px;
    }
    .rt {
      box-sizing: border-box;
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: end;
      background: #f5f6fa;
      border-radius: 8px;
      padding: 8px;
      gap: 4px;

      textarea {
        flex: 1;
        border: none;
        outline: none;
        font-size: 14px;
        color: #333;
        background: transparent;
        min-height: 21px;
        resize: none;
        width: 80%;
        &::placeholder {
          font-size: 14px;
          color: #a1a4ae;
        }
      }

      .btn {
        width: 64px;
        height: 32px;
        line-height: 32px;
        background: #5381ff;
        border-radius: 4px;
        font-size: 14px;
        text-align: center;
        color: #ffffff;
      }
    }
  }
}

.extra {
  position: absolute;
  display: flex;
  top: -32px;
  width: 100vw;
  overflow: hidden;
  height: 24px;

  img {
    width: 24px;
    height: 24px;
  }

  .list-container {
    margin-left: 8px;

    height: 34px;
    // width: calc(100vw - 100px);
    flex: 1;
    overflow-x: scroll;
    padding-bottom: 10px;
  }

  .list {
    padding-right: 20px;

    width: auto;
    display: flex;
    gap: 8px;
    white-space: nowrap;

    .item {
      box-sizing: border-box;
      height: 24px;
      line-height: 24px;
      text-align: center;
      padding: 0 16px;
      border-radius: 12px;
      background: #f5faff;
      border-radius: 12px;
      font-size: 12px;
      color: #a1a4ae;
    }
  }
}

.popup-bottom {
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background: #ffffff;
  border-radius: 64px 0 0 0;
  z-index: 999;
  padding: 16px;
  padding-bottom: 50px;

  .line {
    width: 64px;
    height: 6px;
    background: #e8e9eb;
    border-radius: 3px;
    margin: 0 auto;
  }

  .title {
    margin-top: 16px;
    margin-left: 12px;
    font-size: 14px;
    color: #a1a4ae;
  }

  .list {
    height: 60vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
    font-weight: 500;
    font-size: 14px;
    color: #494a57;

    .item {
      background: #f4f8f9;
      box-shadow: 0 2px 4px 0 #f1fbfe80;
      border-radius: 8px;
      // height: 36px;
      // line-height: 36px;
      padding: 8px;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .pagination {
    margin-top: 12px;
    display: flex;
    justify-content: center;
    gap: 36px;

    > div {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
    }
  }
}
// 语音音阶动画
.prompt-loader {
  width: 98%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 36px auto 6px auto;
}
img {
  width: 48px;
  height: 48px;
}
.prompt-loader .em {
  display: block;
  background: #5381ff;
  width: 3px;
  height: 10%;
  margin-right: 2.5px;
  float: left;
  border-radius: 1px;
}
.prompt-loader .emSilent {
  display: block;
  background: #5381ff;
  width: 3px;
  height: 10px;
  margin-right: 2.5px;
  float: left;
  border-radius: 1px;
}
.prompt-loader .em:last-child,
.prompt-loader .emSilent {
  margin-right: 0px;
}
.prompt-loader .emLeft:nth-child(1),
.prompt-loader .emRight:nth-child(17) {
  animation: load 2.5s 1.4s infinite linear;
}
.prompt-loader .emLeft:nth-child(2),
.prompt-loader .emRight:nth-child(18) {
  animation: load 2.5s 1.2s infinite linear;
}
.prompt-loader .emLeft:nth-child(3),
.prompt-loader .emRight:nth-child(19) {
  animation: load 2.5s 1s infinite linear;
}
.prompt-loader .emLeft:nth-child(4),
.prompt-loader .emRight:nth-child(20) {
  animation: load 2.5s 0.8s infinite linear;
}
.prompt-loader .emLeft:nth-child(5),
.prompt-loader .emRight:nth-child(21) {
  animation: load 2.5s 0.6s infinite linear;
}
.prompt-loader .emLeft:nth-child(6),
.prompt-loader .emRight:nth-child(22) {
  animation: load 2.5s 0.4s infinite linear;
}
.prompt-loader .emLeft:nth-child(7),
.prompt-loader .emRight:nth-child(23) {
  animation: load 2.5s 0.2s infinite linear;
}
.prompt-loader .emLeft:nth-child(8),
.prompt-loader .emRight:nth-child(24) {
  animation: load 2.5s 0s infinite linear;
}
.prompt-loader .emLeft:nth-child(9),
.prompt-loader .emRight:nth-child(25) {
  animation: load 2.5s 0.2s infinite linear;
}
.prompt-loader .emLeft:nth-child(10),
.prompt-loader .emRight:nth-child(26) {
  animation: load 2.5s 0.4s infinite linear;
}
.prompt-loader .emLeft:nth-child(11),
.prompt-loader .emRight:nth-child(27) {
  animation: load 2.5s 0.6s infinite linear;
}
.prompt-loader .emLeft:nth-child(12),
.prompt-loader .emRight:nth-child(28) {
  animation: load 2.5s 0.8s infinite linear;
}
.prompt-loader .emLeft:nth-child(13),
.prompt-loader .emRight:nth-child(29) {
  animation: load 2.5s 1s infinite linear;
}
.prompt-loader .emLeft:nth-child(14),
.prompt-loader .emRight:nth-child(30) {
  animation: load 2.5s 1.2s infinite linear;
}
.prompt-loader .emLeft:nth-child(15),
.prompt-loader .emRight:nth-child(31) {
  animation: load 2.5s 1.4s infinite linear;
}
@keyframes load {
  0% {
    height: 10%;
  }
  50% {
    height: 100%;
  }
  100% {
    height: 10%;
  }
}
.infos{
  font-size: 12px;
  text-align: center;
  margin-top: 5px;
  color: rgb(212, 212, 212);
}
</style>