/* eslint-disable no-undef */
import { reactive, ref } from 'vue';

import CryptoJS from '@/utils/crypto-js';
import Worker from './transcode.worker.js'
const transWorker = new Worker()
export const luyin = function () {
  let API_KEY,API_SECRET,APPID
  if (process.env.NODE_ENV !== 'production') {
     API_KEY = 'ade5f0e101da7ca8cc42178c461138e5';
     API_SECRET = 'YzhlNWQyNmVmY2M4Y2MyNjRjNDk0MDBm';
     APPID = '2b990897';
  }else{
     API_KEY = 'e9c71060f3cf3c04d3dce345b2db9f0a';
     API_SECRET = 'ODk1NjVhZDhkMWM3NjAyNTJmODE0ZGE3';
     APPID = 'f59c2dff';
  }
  let iatWS = reactive({});
  const resultText = ref('');
  const resultTextTemp = ref('');
  const showRecorder = ref(false) // 判断是否显示录音内容
  
  let audioContext = '';
  let language = null
  let accent = null
  // 记录音频数据
  let audioData = [] 
  let handlerInterval = null
  let scriptProcessor = null
  let mediaSource = null
  const status = ref('');

  function getWebSocketUrl() {
    return new Promise((resolve, reject) => {
      // 请求地址根据语种不同变化
      let url = 'wss://iat-api.xfyun.cn/v2/iat'
      const host = 'iat-api.xfyun.cn'
      const apiKey = API_KEY
      const apiSecret = API_SECRET
      const date = new Date().toGMTString()
      const algorithm = 'hmac-sha256'
      const headers = 'host date request-line'
      const signatureOrigin = `host: ${host}\ndate: ${date}\nGET /v2/iat HTTP/1.1`
      const signatureSha = CryptoJS.HmacSHA256(signatureOrigin, apiSecret)
      const signature = CryptoJS.enc.Base64.stringify(signatureSha)
      const authorizationOrigin = `api_key="${apiKey}", algorithm="${algorithm}", headers="${headers}", signature="${signature}"`
      const authorization = btoa(authorizationOrigin)
      url = `${url}?authorization=${authorization}&date=${date}&host=${host}`
      resolve(url)
      reject()
    })
  }

  const IatRecorder = class {
    constructor({ language, accent } = {}) {
      // super(); 
      // closeVoiceFlag.value = 0
      language = language || 'zh_cn'
      accent = accent || 'mandarin'
      // 记录听写结果
      resultText.value = ''
      // wpgs下的听写结果需要中间状态辅助记录
      resultTextTemp.value = ''
      transWorker.onmessage = function (event) {
        audioData.push(...event.data)
      }
    }
  }
  // 修改录音听写状态
  function setStatus(s) {
    // this.onWillStatusChange &&
    //   status.value !== status &&
    //   this.onWillStatusChange(status.value, status)
    status.value = s
  }
  // function setResultText({ r, rTemp } = {}) {
  //   // this.onTextChange && this.onTextChange(resultTextTemp || resultText || '')
  //   r !== undefined && (resultText.value = r)
  //   rTemp !== undefined && (resultTextTemp.value = rTemp)
  // }

  // 连接websocket
  function connectWebSocket() {
    return getWebSocketUrl().then(url => {
      if ('WebSocket' in window) {
        iatWS = new WebSocket(url)
      } else if ('MozWebSocket' in window) {
        iatWS = new MozWebSocket(url)
      } else {
        alert('浏览器不支持WebSocket')
        return
      }
      showRecorder.value = true
      setStatus('init')
      iatWS.onopen = e => {
        setStatus('ing')
        // 重新开始录音
        setTimeout(() => {
          webSocketSend()
        }, 200)
      }
      iatWS.onmessage = e => {
        console.log(e.data,'监听到消息')
        result(e.data)
      }
      iatWS.onerror = e => {
        console.log('走了onerror，才会消失')
        recorderStop()
      }
      iatWS.onclose = e => {
        console.log('走了onclose，才会消失')
        recorderStop()
      }
    })
  }
  // 初始化浏览器录音
  function recorderInit() {
    console.log('安卓进入')
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia
    // 创建音频环境
    console.log('走try上面')
    try {
      audioContext = new (window.AudioContext || window.webkitAudioContext)()
      audioContext.resume()
      if (!audioContext) {
        alert('浏览器不支持webAudioApi相关接口')
        return
      }
    } catch (e) {
      if (!audioContext) {
        alert('浏览器不支持webAudioApi相关接口')
        return
      }
    }

    // 获取浏览器录音权限
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      console.log('走这里')
      navigator.mediaDevices.getUserMedia({
          audio: true
        })
        .then(stream => {
          getMediaSuccess(stream)
        })
        .catch(e => {
          // showRecorder.value = false
          getMediaFail(e)
        })
    } else if (navigator.getUserMedia) {
      console.log('走这里22')
      // alert('系统弹窗')
      navigator.getUserMedia(
        {
          audio: true
        },
        stream => {
          getMediaSuccess(stream)
        },
        function (e) {
          // showRecorder.value = false
          getMediaFail1(e)
        },
      )
    } else {
      if (
        navigator.userAgent.toLowerCase().match(/chrome/) &&
        location.origin.indexOf('https://') < 0
      ) {
        alert(
          'chrome下获取浏览器录音功能，因为安全性问题，需要在localhost或127.0.0.1或https下才能获取权限',
        )
      } else {
        audioContext = ''
        alert('无法获取浏览器录音功能，请升级软件版本')
      }
      audioContext && audioContext.close()
      return
    }
    // 获取浏览器录音权限成功的回调
    const getMediaSuccess = stream => {
      console.log('浏览器获取录音权限成功')
      // 创建一个用于通过JavaScript直接处理音频
      scriptProcessor = audioContext.createScriptProcessor(0, 1, 1)
      scriptProcessor.onaudioprocess = e => {
        // 去处理音频数据
        if (status.value === 'ing') {
          transWorker.postMessage(e.inputBuffer.getChannelData(0))
        }
      }
      // 创建一个新的MediaStreamAudioSourceNode 对象，使来自MediaStream的音频可以被播放和操作
      mediaSource = audioContext.createMediaStreamSource(stream)
      // 连接
      mediaSource.connect(scriptProcessor)
      scriptProcessor.connect(audioContext.destination)
      connectWebSocket()
    }

    const getMediaFail = e => {
      audioContext && audioContext.close()
      audioContext = undefined
      // 关闭websocket
      if (iatWS && iatWS.readyState === 1) {
        iatWS.close()
      }
    }
    const getMediaFail1 = e => {
      audioContext && audioContext.close()
      audioContext = undefined
      // 关闭websocket
      if (iatWS && iatWS.readyState === 1) {
        iatWS.close()
      }
    }
  }
  function recorderStart() {
    if (!audioContext) {
    recorderInit()
    } else {
      audioContext.resume()
      connectWebSocket()
    }
  }
  // 暂停录音
  function recorderStop() {
    // safari下suspend后再次resume录音内容将是空白，设置safari下不做suspend
    if (
      !(
        /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgen)
      )
    ) {
      audioContext && audioContext.suspend()
    }
    showRecorder.value = false
    clearInterval(handlerInterval)
    if (iatWS && iatWS.readyState === 1) {
      iatWS.close()
    }
    setStatus('end')
  }
  // 对处理后的音频数据进行base64编码，
  function toBase64(buffer) {
    let binary = ''
    const bytes = new Uint8Array(buffer)
    const len = bytes.byteLength
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
  }
  // 向webSocket发送数据
  function webSocketSend() {
    if (iatWS.readyState !== 1) {
      clearInterval(handlerInterval)
      return
    }
    let audio = audioData.splice(0, 1280)
    const params = {
      common: {
        app_id: APPID,
      },
      business: {
        language: language, //小语种可在控制台--语音听写（流式）--方言/语种处添加试用
        domain: 'iat',
        accent: accent, //中文方言可在控制台--语音听写（流式）--方言/语种处添加试用
        vad_eos: 5000,
        dwa: 'wpgs',
      },
      data: {
        status: 0,
        format: 'audio/L16;rate=16000',
        encoding: 'raw',
        audio: toBase64(audio),
      },
    }
    iatWS.send(JSON.stringify(params))
    handlerInterval = setInterval(() => {
      // websocket未连接
      console.log(iatWS.readyState,'iatWS.readyStateiatWS.readyState')
      if (iatWS.readyState !== 1) {
        console.log('websocket未连接')
        clearInterval(handlerInterval)
        audioData = []
        return
      }
      if (audioData.length === 0) {
        //点击暂停录音
        // console.log('自动关闭', status)
        if (status.value === 'end') {
          iatWS.send(
            JSON.stringify({
              data: {
                status: 2,
                format: 'audio/L16;rate=16000',
                encoding: 'raw',
                audio: '',
              },
            }),
          )
          audioData = []
          clearInterval(handlerInterval)
        }
        return false
      }
      let audio2 = audioData.splice(0, 1280)
      // 中间帧
      iatWS.send(
        JSON.stringify({
          data: {
            status: 1,
            format: 'audio/L16;rate=16000',
            encoding: 'raw',
            audio: toBase64(audio2),
          },
        }),
      )
    }, 40)
  }
  function result(resultData) {
    // 识别结束
    const jsonData = JSON.parse(resultData)
    if (jsonData.data && jsonData.data.result) {
      const data = jsonData.data.result
      let str = ''
      // const resultStr = ''
      const ws = data.ws
      for (let i = 0; i < ws.length; i++) {
        str = str + ws[i].cw[0].w
      }
      // console.log('识别的结果为：', str)
      // 开启wpgs会有此字段(前提：在控制台开通动态修正功能)
      // 取值为 "apd"时表示该片结果是追加到前面的最终结果；取值为"rpl" 时表示替换前面的部分结果，替换范围为rg字段
      if (data.pgs) {
        if (data.pgs === 'apd') {
          // 将resultTextTemp同步给resultText
          // setResultText({
            resultText.value = resultTextTemp.value
          // })
        }
        // 将结果存储在resultTextTemp中
        // setResultText({
          resultTextTemp.value = resultText.value + str
        // })
      } else {
        // setResultText({
          resultText.value = resultText.value + str
        // })
      }
    }
    console.log(resultText.value,'sssssssss')
    if (jsonData.code === 0 && jsonData.data.status === 2) {
      iatWS.close()
    }
    if (jsonData.code !== 0) {
      iatWS.close()
    }
  }
  function luyinStart() {
    if(showRecorder.value){
      recorderStop()
    }else{
      recorderStart()
      resultText.value = ''
      resultTextTemp.value = ''
    }
  }
  function luyinStop() {
    recorderStop()
  }
  return{
    IatRecorder,
    luyinStart,
    luyinStop,
    iatWS,
    resultText,
    showRecorder
  }
}
// export default IatRecorder


