<template>
  <div>
    <div class="nav-bar-top">
      <div class="left" @click="goBack">
        <van-icon name="arrow-left" size="24" color="#1D1D2C" />
      </div>
      <div class="center">AI聊天</div>
      <div class="right"></div>
    </div>
    <div style="height: 88px"></div>
    <div class="nav-bar">
      <div class="lt">
        <img src="@assets/more.png" alt="" @click="handleSide" />
      </div>
      <div class="center van-ellipsis">
        {{
          messageStore.title
            ? messageStore.title
            : route.name == 'message'
            ? '新对话窗口'
            : ''
        }}
      </div>
      <div class="rt" @click="addMessage">
        <img src="@assets/add.png" alt="" />
        新增
      </div>
    </div>
    <div v-show="showWarning" class="aiWarning">禁止利用AI功能从事相关违法活动！</div>
    <div class="nav-block"></div>
  </div>
</template>

<script setup>
import { useUserStore } from '@/stores/user.js';
import { useMessageStore } from '@/stores/message.js';
import { luyin } from '@/recorder/IatRecorder.js';

import { useRoute, useRouter } from 'vue-router';
import { watch, defineProps, ref } from 'vue';
const showWarning = ref(true)
const route = useRoute();
const router = useRouter();
const userStore = useUserStore();
const messageStore = useMessageStore();
const props = defineProps({
  showBottom: {
    type: Boolean,
    default: true,
  },
  title:{
    type: String,
    default: '',
  }
});
// defineProps(['title']);
let {
  luyinStop
} = luyin();
watch(props, () => {
  if(!props.showBottom){
    console.log(props.showBottom,'props.showBottom')
    showWarning.value = true
    setTimeout(() => {
      showWarning.value = false
    }, 6000);
  }
})
setTimeout(() => {
  showWarning.value = false
}, 6000);
function handleSide() {
  userStore.showSide = true;
}

function addMessage() {
  luyinStop()
  setTimeout(() =>{
    router.push({
      name: 'home',
    });
    setTimeout(() => {
      router.push({
        name: 'message',
      });
    }, 150);
  },200)
}
function goBack() {
  luyinStop()
  setTimeout(() => {
    if (route.name === 'message') {
      router.push('home');
      // window.history.go(-1);
    } else {
      sessionStorage.clear();
      if (window.isAndroid) {
        android.finish();
      }
      if (window.isIOS) {
        window.webkit.messageHandlers.AppModel.postMessage('back');
      }
    }
  }, 200);
}
</script>

<style lang="less" scoped>
.nav-bar-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  height: 84px;
  padding:0 8px;

  padding-top: 50px;
  display: flex;
  justify-content: space-between;
  background: #fff;
  z-index: 99;
  .right {
    width: 16px;
  }
}
.nav-bar {
  box-sizing: border-box;
  position: fixed;
  top: 84px;
  left: 0;
  width: 100vw;
  height: 48px;
  background: #101736;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  z-index: 99;

  > div {
    flex: 1;
  }
  .lt {
    img {
      width: 16px;
      height: 16px;
    }
  }

  .center {
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    width: 200px;
    text-align: center;
  }

  .rt {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    font-size: 14px;
    color: #ffffff;

    img {
      width: 16px;
      height: 16px;
    }
  }
}
.aiWarning{
  position: fixed;
  width: 375px;
  height: 28px;
  line-height: 28px;
  background: #FFF5DC;
  font-family: PingFang-SC-Medium;
  font-weight: 500;
  font-size: 14px;
  color: #FE8A51;
  text-align: center;
  top: 8.25rem;
}
.nav-block {
  height: 48px;
  width: 100vw;
}
</style>